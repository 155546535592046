import { Duration } from "luxon";
import { IncomeEmploymentStatus } from "@aletheia/graphql";
export var DEFAULT_INCOME_EMPLOYMENT_STATUS_OPTIONS = [
    IncomeEmploymentStatus.Employed,
    IncomeEmploymentStatus.Student,
    IncomeEmploymentStatus.Retired,
    IncomeEmploymentStatus.Unemployed, 
];
export var DEFAULT_PLEDGE_PERCENTAGE = 10;
export var DEFAULT_PLEDGE_DURATION = "infinity";
export var DEFAULT_TRIAL_PLEDGE_DURATION = Duration.fromObject({
    years: 1
});
export var DEFAULT_DURATION_OPTIONS = [
    Duration.fromObject({
        months: 6
    }),
    DEFAULT_TRIAL_PLEDGE_DURATION,
    Duration.fromObject({
        years: 2
    }),
    Duration.fromObject({
        years: 3
    }),
    Duration.fromObject({
        years: 5
    }), 
];
