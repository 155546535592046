import { z } from "zod";
import { IncomeEmploymentStatus, PledgeType } from "@aletheia/graphql/graphql";
import { DateTimeZ, DurationZ, expectType } from "./utils";
import { emailSchema } from "../../../../../utils/validators";
import { DateTime } from "luxon";
var currentDate = DateTime.now();
export var minStartDate = currentDate.minus({
    years: 10
});
export var maxStartDate = currentDate.plus({
    years: 10
});
export var isValidStartDate = function(startDate) {
    return startDate >= minStartDate && startDate <= maxStartDate;
};
// Income percentages
var GWWC_PLEDGE_MIN_INCOME_PERCENTAGE = 10;
var GWWC_PLEDGE_MAX_INCOME_PERCENTAGE = 99;
var TRIAL_PLEDGE_MIN_INCOME_PERCENTAGE = 1;
var TRIAL_PLEDGE_MAX_INCOME_PERCENTAGE = 99;
// Wealth percentages
var GWWC_PLEDGE_MIN_WEALTH_PERCENTAGE = 1;
var GWWC_PLEDGE_MAX_WEALTH_PERCENTAGE = 99.9;
var TRIAL_PLEDGE_MIN_WEALTH_PERCENTAGE = 0.1;
var TRIAL_PLEDGE_MAX_WEALTH_PERCENTAGE = 99.9;
/* PLEDGE */ export var SimplePledgeSchema = z.object({
    id: z.string().nullish(),
    pledgeType: z.enum([
        PledgeType.GivingWhatWeCan,
        PledgeType.TryGiving
    ]),
    incomePercentage: z.number().min(1).max(99),
    wealthPercentage: z.number().min(0).max(99.9).nullish(),
    startDate: DateTimeZ.refine(function(val) {
        return isValidStartDate(val);
    }),
    duration: z.union([
        DurationZ,
        z.literal("infinity")
    ]),
    motivation: z.string().nullish(),
    locale: z.string().nullish(),
    termsAgreed: z.boolean(),
    employmentStatus: z.nativeEnum(IncomeEmploymentStatus)
});
export var isValidIncomePercentage = function(incomePercentage, pledgeType) {
    if (!pledgeType || !incomePercentage) return false;
    if (pledgeType === PledgeType.GivingWhatWeCan) {
        return incomePercentage >= GWWC_PLEDGE_MIN_INCOME_PERCENTAGE && incomePercentage <= GWWC_PLEDGE_MAX_INCOME_PERCENTAGE;
    }
    if (pledgeType === PledgeType.TryGiving) {
        return incomePercentage >= TRIAL_PLEDGE_MIN_INCOME_PERCENTAGE && incomePercentage <= TRIAL_PLEDGE_MAX_INCOME_PERCENTAGE;
    }
    return false;
};
export var isValidWealthPercentage = function(wealthPercentage, pledgeType) {
    if (!pledgeType) return false;
    if (!wealthPercentage) return true;
    if (pledgeType === PledgeType.GivingWhatWeCan) {
        return wealthPercentage >= GWWC_PLEDGE_MIN_WEALTH_PERCENTAGE && wealthPercentage <= GWWC_PLEDGE_MAX_WEALTH_PERCENTAGE;
    }
    if (pledgeType === PledgeType.TryGiving) {
        return wealthPercentage >= TRIAL_PLEDGE_MIN_WEALTH_PERCENTAGE && wealthPercentage <= TRIAL_PLEDGE_MAX_WEALTH_PERCENTAGE;
    }
    return false;
};
/* ADDRESS */ export var AddressUnsavedSchema = z.object({
    id: z.string().nullish(),
    firstName: z.string().min(1).nullish(),
    lastName: z.string().min(1).nullish(),
    organizationName: z.string().min(1).nullish(),
    houseNumber: z.string().min(1).nullish(),
    addressLine1: z.string().min(1).nullish(),
    addressLine2: z.string().nullish(),
    city: z.string().min(1).nullish(),
    region: z.string().min(1).nullish(),
    postalCode: z.string().min(1).nullish(),
    countryCode: z.string().min(2).max(2).nullish(),
    latitude: z.number().nullish(),
    longitude: z.number().nullish(),
    googlePlaceId: z.string().nullish()
});
expectType({});
expectType({});
/* PERSON */ export var PersonNameSchema = z.object({
    firstName: z.string().min(1).nullish(),
    lastName: z.string().min(1).nullish()
});
export var PersonEmailSchema = z.object({
    email: emailSchema.nullish().or(z.literal("").transform(function() {
        return null;
    }))
});
export var TemporaryPersonSchema = z.object({
    sessionToken: z.string().uuid().nullish()
});
export var PersonSchema = PersonNameSchema.merge(PersonEmailSchema).merge(TemporaryPersonSchema);
export var PersonWithAddressFormSchema = AddressUnsavedSchema.merge(PersonNameSchema);
