import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { captureException } from "@sentry/nextjs";
import { useEffect } from "react";
import { usePerson } from "@aletheia/common/components/PersonProvider";
import { useSignPledgeByTemporaryPersonMutation, useSignPledgeMutation } from "@aletheia/graphql";
import { MAILCHIMP_BASE_PATH } from "../../../../../utils/mailchimp";
import { useCampaign } from "../../../../Campaign/provider";
import { useReferral } from "../../../../utils/hooks/useReferrals";
import { PersonSchema, SimplePledgeSchema } from "../validators/validators";
import { usePledgeClub } from "../pledgeClubContext";
// Hook to expose simple method to mutate pledge and person at the same time during signup
export var useSignUpMutation = function(updatePledge) {
    var Person = usePerson().Person;
    var campaign = useCampaign().campaign;
    var referral = useReferral().referral;
    var pledgeClub = usePledgeClub();
    // Gather the mutation methods and state for the Pledge and Person
    var ref = _sliced_to_array(useSignPledgeMutation(), 2), signPledgeMutation = ref[0], ref1 = ref[1], pledgeByPersonMutationData = ref1.data, pledgeByPersonMutationLoading = ref1.loading, pledgeByPersonMutationError = ref1.error;
    var ref2 = _sliced_to_array(useSignPledgeByTemporaryPersonMutation(), 2), signPledgeByTemporaryPersonMutation = ref2[0], ref3 = ref2[1], pledgeByTemporaryPersonMutationData = ref3.data, pledgeByTemporaryPersonMutationLoading = ref3.loading, pledgeByTemporaryPersonMutationError = ref3.error;
    var pledgeMutationLoading = pledgeByPersonMutationLoading || pledgeByTemporaryPersonMutationLoading;
    var pledgeMutationError = pledgeByPersonMutationError || pledgeByTemporaryPersonMutationError;
    var pledgeMutationData = pledgeByPersonMutationData || pledgeByTemporaryPersonMutationData;
    // Combine the loading/error/success and state of the individual hooks into 1 state
    var loading = pledgeMutationLoading;
    var error = pledgeMutationError;
    var success = !error && !!pledgeMutationData;
    useEffect(function() {
        if (error) captureException(error, {
            user: {
                id: Person === null || Person === void 0 ? void 0 : Person.id
            }
        });
    }, [
        error
    ]);
    var handleNewPledge = function(newPledge, email) {
        var ref;
        updatePledge({
            id: newPledge.id,
            pledgeType: newPledge.pledgeType
        });
        if (email) {
            // Skip if dev or testing
            if (process.env.NEXT_PUBLIC_BASE_URL === "http://localhost:3000") return;
            // Add user to mailchimp with pledge type
            fetch("".concat(MAILCHIMP_BASE_PATH, "&EMAIL=").concat(encodeURIComponent(email), "&PLDG_TYPE=").concat(newPledge.pledgeType), {
                method: "POST"
            });
        } else {
            captureException("Missing email during pledge flow", {
                user: {
                    id: Person === null || Person === void 0 ? void 0 : Person.id
                }
            });
        }
        // We should store a pledge club if and only if the URL is not /pledge/flow
        var isPledgeClubSignupLocation = !window.location.pathname.match(/\/pledge\/flow\/?$/);
        var isPledgeClubStored = !!(newPledge === null || newPledge === void 0 ? void 0 : (ref = newPledge.metadata) === null || ref === void 0 ? void 0 : ref.pledgeClub);
        if (isPledgeClubSignupLocation !== isPledgeClubStored) {
            var ref1;
            captureException("Pledge club signup failed to store the pledgeClub", {
                user: {
                    id: Person === null || Person === void 0 ? void 0 : Person.id
                },
                extra: {
                    newPledgeId: newPledge.id,
                    location: window.location.pathname,
                    storedPledgeClub: newPledge === null || newPledge === void 0 ? void 0 : (ref1 = newPledge.metadata) === null || ref1 === void 0 ? void 0 : ref1.pledgeClub
                }
            });
        }
    };
    // The exposed combined mutation method
    var mutate = function() {
        var _ref = _async_to_generator(function(param) {
            var pledge, person, personId, sessionToken, parsedPledge, parsedPerson, employmentStatus, validPledge, validPerson, metadata, endDate, variables, ref, ref1, result, newPledge, ref2, ref3, result1, newPledge1, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        pledge = param.pledge, person = param.person;
                        personId = Person === null || Person === void 0 ? void 0 : Person.id;
                        sessionToken = person.sessionToken;
                        if (!personId && !sessionToken) {
                            return [
                                2,
                                false
                            ];
                        }
                        _state.label = 1;
                    case 1:
                        _state.trys.push([
                            1,
                            6,
                            ,
                            7
                        ]);
                        parsedPledge = SimplePledgeSchema.safeParse(pledge);
                        parsedPerson = PersonSchema.safeParse(person);
                        employmentStatus = pledge.employmentStatus;
                        if (!employmentStatus) {
                            throw new Error("Employment status is required");
                        }
                        if (!parsedPledge.success) {
                            throw new Error("Invalid Pledge: ".concat(JSON.stringify(parsedPledge.error.errors)));
                        }
                        if (!parsedPerson.success) {
                            throw new Error("Invalid Person: ".concat(JSON.stringify(parsedPerson.error.errors)));
                        }
                        validPledge = parsedPledge.data;
                        validPerson = parsedPerson.data;
                        metadata = {
                            campaign: campaign,
                            referral: referral,
                            pledgeClub: pledgeClub === null || pledgeClub === void 0 ? void 0 : pledgeClub.parfitSlug,
                            employmentStatus: employmentStatus,
                            source: "pledge-flow"
                        };
                        endDate = validPledge.duration === "infinity" ? "infinity" : validPledge.startDate.plus(validPledge.duration).minus({
                            days: 1
                        }) // First day is included, so we need to subtract 1 day from the duration
                        .toISODate();
                        variables = {
                            startDate: validPledge.startDate.toISODate(),
                            endDate: endDate,
                            incomePercentage: validPledge.incomePercentage.toString(),
                            wealthPercentage: validPledge.wealthPercentage ? validPledge.wealthPercentage.toString() : undefined,
                            pledgeType: validPledge.pledgeType,
                            motivation: validPledge.motivation,
                            locale: validPledge.locale || "en",
                            metadata: metadata,
                            employmentStatus: employmentStatus,
                            firstName: validPerson.firstName,
                            lastName: validPerson.lastName
                        };
                        if (!personId) return [
                            3,
                            3
                        ];
                        return [
                            4,
                            signPledgeMutation({
                                variables: _object_spread({
                                    personId: personId
                                }, variables)
                            })
                        ];
                    case 2:
                        result = _state.sent();
                        newPledge = (ref = result.data) === null || ref === void 0 ? void 0 : (ref1 = ref.signPledge) === null || ref1 === void 0 ? void 0 : ref1.pledge;
                        if (newPledge) {
                            handleNewPledge(newPledge, Person.email);
                        }
                        return [
                            3,
                            5
                        ];
                    case 3:
                        if (!sessionToken) return [
                            3,
                            5
                        ];
                        return [
                            4,
                            signPledgeByTemporaryPersonMutation({
                                variables: _object_spread({
                                    sessionToken: sessionToken
                                }, variables)
                            })
                        ];
                    case 4:
                        result1 = _state.sent();
                        newPledge1 = (ref2 = result1.data) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.signPledgeByTemporaryPerson) === null || ref3 === void 0 ? void 0 : ref3.pledge;
                        if (newPledge1) {
                            handleNewPledge(newPledge1, validPerson.email);
                        }
                        _state.label = 5;
                    case 5:
                        return [
                            3,
                            7
                        ];
                    case 6:
                        error = _state.sent();
                        console.error(error);
                        captureException(error, {
                            user: {
                                id: Person === null || Person === void 0 ? void 0 : Person.id
                            }
                        });
                        return [
                            2,
                            false
                        ];
                    case 7:
                        return [
                            2,
                            true
                        ];
                }
            });
        });
        return function mutate(_) {
            return _ref.apply(this, arguments);
        };
    }();
    return {
        mutate: mutate,
        loading: loading,
        error: error,
        success: success
    };
};
